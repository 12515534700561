const site = {
  pathPrefix: '/my-crossdressing',
  title: 'A blog about a transgender person who used to be a crossdresser',
  titlePL: 'Blog o osobie transpłciowej, która była crossdresserem',
  author: 'Sonia Yasme',
  description: 'A blog about crossdressing, written from the perspective of someone who was a crossdresser and became a transgender person.',
  descriptionPL: 'Blog o crossdressingu, pisany z perspektywy osoby, która była crossdresserem, a stała się osobą transpłciową.',
  siteUrl: 'https://mycrossdressing.com',
  twitter: 'SYasme',
  medium: 'soniayasme',
  facebook: 'soniayasme',
  instagram: 'soniayasme',
  mail: 'soniayasme@gmail.com',
  disqusShortName: 'mycrossdressing',
  googleTrackingId: '',
  lang: 'en',
  displayTranslations: true,
};

const supportedLanguages = {
  en: 'English',
  pl: 'Polski',
};

module.exports = {
  site,
  supportedLanguages,
};
