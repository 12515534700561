module.exports = {
  tHome: 'Crossdressing Blog',
  tAbout: 'O mnie',
  tCategories: 'Kategorie',
  tTutorials: 'Poradniki',
  tClothes: 'Ubrania',
  tShoes: 'Buty',
  tMakeup: 'Makijaż',
  tLinks: 'Polecane linki',
  tLifestyle: 'Styl życia',
  tMyStory: 'Moja historia',
  tRelativePosts: 'Polecane posty',
  tFollowTwitterDescription: 'Obserwuj na Twitterze',
  tTags: 'Tagi',
  tIndTitle: 'Crossdressing Blog Polska',
  taIndKeywords: [`blog`, 'transpłciowość', `crossdressing`, `crossdresser`, `transwestyta`],
  tfIndCountPosts: count => `${count} Posty`,
  tfTagHeader: (totalCount, tag) =>
    `${totalCount} post${totalCount === 1 ? '' : 's'} otagowane z "${tag}"`,
  t404Title: 'Nie znaleziono',
  t404Content: 'Podany adres nie istnieje.',
  bio: 'Jestem osobą transpłciową. Urodziłam się jako mężczyzna i przez długi czas funkcjonowałam w takiej formie. Jednak moja prawdziwa natura dała o sobie mocniej znać i będę przechodzić tranzycję w najbliższej przyszłości. Tworzę szczęśliwy związek z kobietą mojego życia, która mnie wspiera z całego serca. Dlaczego blog nazywa się MyCrossdressing? Dlatego, że wcześniej uważałam siebie za crossdressera, że jestem w stanie żyć jako mężczyzna. <a href="/pl/zawiodlam-siebie-i-spolecznosc/">Myliłam się i napisałam o tym post.</a> Blog zostaje, nowe wpisy prawdopodobnie będą się ukazywać tak jak dotychczas. Dodatkowo chcę również pomóc osobom zagubionym w tym świecie poprzez poradniki, jak i bezpośrednią rozmowę. Piszcie pytania w komentarzach na blogu lub bezpośrednio do mnie na maila/Instagrama/Facebooka. W miarę możliwości postaram się pomóc.',
  tSortByPopularity: 'Sortuj posty według popularności',
  tSortByDate: 'Sortuj posty według daty',
  tPrevious: 'Poprzednia strona',
  tNext: 'Następna strona',
};
