module.exports = {
  tHome: 'Crossdressing Blog',
  tAbout: 'About me',
  tCategories: 'Categories',
  tTutorials: 'Tutorials',
  tClothes: 'Clothes',
  tShoes: 'Shoes',
  tMakeup: 'Makeup',
  tLinks: 'Links',
  tLifestyle: 'Lifestyle',
  tMyStory: 'My story',
  tRelativePosts: 'Relative Posts',
  tFollowTwitterDescription: 'You should follow her on Twitter',
  tTags: 'Tags',
  tIndTitle: 'Crossdressing Blog',
  taIndKeywords: [`blog`, `transgender`, `crossdressing`, `crossdresser`, `transvestite`],
  tfIndCountPosts: count => `${count} Posts`,
  tfTagHeader: (totalCount, tag) =>
    `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with "${tag}"`,
  t404Title: 'Not Found',
  t404Content: 'You just hit a route that doesn&#39;t exist... the sadness.',
  bio: 'I am a transgender person. I was born male and lived that way for a long time. However, my true nature has revealed itself more strongly, and I will be transitioning in the near future. I am in a happy relationship with the woman of my life, who supports me wholeheartedly.\n' +
    'Why is the blog called MyCrossdressing? Because I previously considered myself a crossdresser, believing I could live as a man. <a href="/i-failed-myself-and-the-community/">I was wrong, and I wrote a post about it.</a> The blog will remain, and new entries will likely continue to appear as before.\n' +
    'Additionally, I want to help those who feel lost in this world through guides and direct conversations. Feel free to ask questions in the blog comments or contact me directly via email, Instagram, or Facebook. I will do my best to help as much as I can.',
  tSortByDate: 'Sort posts by date',
  tSortByPopularity: 'Sort posts by popularity',
  tPrevious: 'Previous',
  tNext: 'Next',
};
